/* Home section */
.homepage-video {
    object-fit: cover;
    width: 100%;
    position: fixed;
    z-index: -1;
    height: 100vh;
}
.home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.home > h1 {
    font-size: 100px;
    text-align: center;
    font-family: 'Permanent Marker', cursive;
    color: gold;
    transition: all 3s;
}
.home > h1:hover{
    font-size: 150px;
}
.home > h2{
    font-size: 40px;
}
.scroll {
    position: fixed;
    width: 4%;
    right: 4%;
    top: 85%;
}
.link-container.home-page {
    position: absolute;
    top: 90%;
}
.link-container.contact-page {
    position: absolute;
    top: 390%;
}
.link-container img {
    margin: 0 25px;
    width: 55px;
    opacity: 0.8;
}
.link-container img:hover {
    opacity: 1;
}

/* About section */
.about {
    height: 100vh;
    text-align: center;
    background: rgb(230, 230, 230);
    color: rgb(161, 20, 20);
}
.about-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin:auto;
}
.about-container h1 {
    width: 100%;
    padding-bottom: 4%;
}
.about-container img {
    padding-top: 10%;
    width: 30%;
}

/* Projects Section */
.projects {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url("https://images.unsplash.com/photo-1589642314445-999ac13b0075?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1353&q=80");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    color: white;
}

/* Contact Section */
.contact {
    height: 100vh;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-image: url("https://images.unsplash.com/photo-1473181488821-2d23949a045a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    color: white;
}

/* Media Queries */
@media (max-width: 1536px) {
    .scroll {
        top: 88%;
    }
}
@media (max-width: 1440px) {
    .scroll {
        top: 88%;
    }
}
@media (max-width: 1366px) {
    .home > h1 {
        font-size: 80px;
    }
}
@media (max-width: 1200px) {
    .home > h1 {
        font-size: 80px;
    }
    .link-container.contact-page {
        top: 420%;
    }
}
@media (max-width: 1024px) and (max-height:600px) {
    .link-container.home-page {
        top: 85%;
    }
    .about-container h1, h2 {
        font-size: 18px;
    }
    .about-container h1 {
        font-size: 20px;
    }
    .about-container img {
        width: 30%;
    }
    .link-container.contact-page {
        width: 10%;
        left: 76%;
        top: 380%;
    }
}
@media screen and (max-width:820px){
    .about-container img {
        width: 60%;
    }
}
@media screen and (max-width:768px){
    .link-container.home-page {
        position: fixed;
        left: 82%;
        height: 100%;
        width: 11%;
        top: 70%;
    }
    .link-container.contact-page {
        display: none;
    }
    .link-container img {
        opacity: 1;
        width: 60%;
        padding-bottom: 10%;
    }
    .scroll {
        display:none;
    }
}
@media (max-width: 540px) and (min-height:720px) {
    .home > h1 {
        font-size: 50px;
    }
    .home > h1:hover{
        font-size: 80px;
    }
    .home > h2 {
        font-size: 30px;
    }
    .scroll {
        display: none;
    }
    .about-container h1 {
        font-size: 23px;
    }
    .about-container h2 {
        font-size: 18px;
    }
}
@media screen and (max-width: 414px) {
    .home > h1 {
        font-size: 50px;
    }
    .home > h1:hover{
        font-size: 70px;
    }
    .home > h2 {
        font-size: 30px;
    }
    .scroll {
        display: none;
    }
    .about-container h1 {
        font-size: 23px;
        margin-left: 0;
    }
    .about-container h2 {
        font-size: 18px;
    }
    .about-container img {
        width: 80%;
    }
    .link-container img {
        width: 90%;
    }
    .link-container.home-page {
        left: 78%;
    }
}
@media screen and (max-width: 360px) {
    .about-container h1 {
        font-size: 21px;
    }
    .about-container h2 {
        font-size: 16px;
    }
}
@media screen and (max-width: 320px) {
    .about-container h1 {
        font-size: 18px;
    }
    .about-container h2 {
        font-size: 14px;
    }
}
@media screen and (max-width: 280px) {
    .link-container {
        left: 78%;
    }
}