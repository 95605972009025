*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Hind Guntur', sans-serif;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}