.NavbarItems {
    border-color: transparent;
    border-bottom: 0;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
    background: transparent;
}
.NavbarItems.active {
    background: linear-gradient(90deg, rgb(120, 118, 141) 0%, rgb(39, 39, 44) 100%);
}

.logo{
    color: rgb(233, 233, 233);
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 25px;
    cursor:none;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10%;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-menu > .active {
    filter: brightness(200%);
}

.nav-links {
    color: white;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    filter:brightness(50%);
    cursor: pointer;
}

.nav-links:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(200%);
}

.burger{
    display: none;
    cursor: pointer;
}

.burger div{
    width: 28px;
    height: 3px;
    background-color: rgb(233, 233, 233);
    margin: 5px;
    transition: all 0.3s ease;
}


/* Media Queries */

@media (max-width: 1366px) {
    .logo {
        font-size: 18px;
    }
    .nav-links {
        font-size: 15px;
    }
}

@media screen and (max-width:1000px){
    .nav-menu {
        width: 60vw;
    }
}

@media screen and (max-width:768px){
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 360px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #474755;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        border-radius: 0;
    }

    .logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%)
    }

    .burger {
        display:block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor:pointer;
    }
}
@media (max-width: 540px) and (min-height:720px) {
    .nav-menu {
        top: 58px;
    }
}
@media screen and (max-width:414px){
    .logo {
        transform: translate(10%, 60%)
    }
    .nav-menu {
        top: 59px;
    }
}
@media screen and (max-width:411px){
    .nav-menu {
        top: 65px;
    }
}
@media screen and (max-width:411px){
    .logo {
        transform: translate(10%, 45%)
    }
    .nav-menu {
        top: 56px;
    }
}
@media (max-width:411px) and (min-height:823px){
    .logo {
        transform: translate(10%, 52%)
    }
    .nav-menu {
        top: 63.5px;
    }
}
@media (max-width:384px) and (min-height:854px){
    .nav-menu {
        top: 68px;
    }
}
@media (max-width:375px) and (min-height:667px){
    .nav-menu {
        top: 53px;
    }
    .burger {
        transform: translate(-100%, 45%);
    }
}
@media (max-width:375px) and (min-height:812px){
    .nav-menu {
        top: 63px;
    }
}
@media screen and (max-width:360px){
    .nav-menu {
        top: 50px;
    }
    .burger {
        transform: translate(-100%, 45%);
    }
}
@media screen and (max-width:320px){
    .nav-menu {
        top: 45px;
    }
    .logo {
        font-size: 16px;
        transform: translate(7%, 29%)
    }
    .burger {
        transform: translate(-60%, 30%);
    }
}
@media screen and (max-width:280px){
    .nav-menu {
        top: 50px;
    }
    .logo {
        font-size: 14px;
        transform: translate(7%, 55%)
    }
    .burger {
        transform: translate(-40%, 40%);
    }
}

.cross1{
    transform: rotate(-45deg) translate(-5px, 6px);
}
.cross2{
    opacity: 0;
}
.cross3{
    transform: rotate(45deg) translate(-5px, -6px);
}