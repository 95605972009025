.contact-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contact-container > h1 {
    background: rgba(24, 24, 24, 0.664);
    border-radius: 5px;
    padding: .5%;
    margin-bottom: 1%;
    text-align: center;
}

.form-row {
    display: flex;
}

.bottom {
    display: flex;
    flex-direction: column;
}

.form-group {
    width: 45%;
}

.col {
    width: 100%;
    padding-bottom: 2%;
}

.form-control {
    width: 100%;
    padding: 2%;
    font-size: 15px;
    border-radius: 5px;
}

.btn {
    padding: 1%;
    margin-top: 1%;
    border-radius: 5px;
    background: rgb(61, 61, 61);
    color: white;
    font-size: 20px;
    font-weight: 600;
    filter:brightness(80%);
}

.btn:hover {
    filter:brightness(60%);
    cursor: pointer;
}

@media screen and (max-width:768px){
    .contact-container > h1 {
        font-size: 25px;
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 414px) {
    .form-group {
        width: 60%;
    }
    .bottom {
        height: 40vh;
    }
}