.card{
    margin: 100px auto 0;
    width: 350px;
    height: 550px;
    perspective: 1000px;
}

.cards {
    width: 100%;
}

.cards h1 {
    text-align: center;
    margin-top: 8%;
}

.card__inner {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
}

.card__inner.is-flipped {
    transform: rotateY(180deg)
}

.card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.24);
}

.card__face--front:hover {
    filter: brightness(1.1)
}

.card__face--front {
    background-image: linear-gradient(to bottom right, #FB9F8B, #077B88);
    display: flex;
    align-items: center;
    justify-content: center;
}

.card__face--front h2 {
    color: white;
    font-size: 32px;
}

.card__face--front {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card__face--back {
    background-color: white;
    transform: rotateY(180deg);
}

.card__content {
    width: 100%;
    height: 100%;
}

.card__header {
    position: relative;
    padding: 30px 30px 40px;
}

.card__header:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 0 0 50% 0;
    background-image: linear-gradient(to bottom left, #FB9F8B 10%, #077B88 115%);
}

.project-img {
    width: 100%;
    height: 40%;
}

.card__header h2 {
    display: inline;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
}

.card__body {
    padding: 3% 3% 0;
}

.card__body h3 {
    color: black;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 15px;
}

.card__body p {
    color: black;
    font-size: 1.1rem;
    line-height: 1.5;
}

.cards__items {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
}

.card__links {
    text-align: center;
    margin-top: 2%;
}

.card__links a {
    margin: 0 10%;
}

.card__links img {
    transition: all 0.4s ease-in-out 0s;
}

.card__links img:hover {
    filter: brightness(1.1);
    transform: rotate(360deg);
    transition: all 0.4s ease-in-out 0s;
}

.carousel {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.slide {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    z-index: 1;
    transform: scale(1.05);
    transition-duration: 1s;
}

.carousel-button {
    position: absolute;
    background: none;
    border: none;
    font-size: 4rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, .5);
    cursor: pointer;
    border-radius: .25rem;
    padding: 0 .5rem;
    background-color: rgba(0, 0, 0, .1);
    z-index: 2;
}

.carousel-button:hover {
    cursor: pointer;
}

.carousel-button:hover,
.carousel-button:focus {
    color: white;
    background-color: rgba(0, 0, 0, .2);
}

.carousel-button:focus {
    outline: 1px solid black;
}

.carousel-button.prev {
    left: 1rem;
}

.carousel-button.next {
    right: 1rem;
}

/* Media Queries */

@media only screen and (max-width: 1600px) {
    .card{
        width: 300px;
        height: 500px;
    }
}
@media (max-width: 1280px) and (max-height:800px) {
    .cards > h1 {
        margin-top: 7%;
    }
    .card {
        width: 280px;
        height: 480px;
        margin-top: 3%;
    }
}
@media only screen and (max-width: 1024px) {
    .cards > h1 {
        margin-top: 15%;
    }
    .carousel-button {
        top: 35%;
    }
}
@media (max-width: 1024px) and (max-height:600px) {
    .cards > h1 {
        margin-top: 7%;
        font-size: 1.5rem;
    }
    .card {
        width: 260px;
        height: 380px;
        margin-top: 3%;
    }
    .card__body h3 {
        font-size: 1rem;
    }
    .card__body p {
        font-size: .8rem;
    }
}
@media only screen and (max-width: 600px) {
    .card__body h3 {
        font-size: 1rem;
    }
    .card__body p {
        font-size: .9rem;
    }
    .card{
        width: 250px;
        height: 450px;
        margin-top: 10%;
    }
}
@media only screen and (max-width: 414px) {
    .cards > h1 {
        margin-top: 24%;
        font-size: 1.7rem;
    }
    .card{
        width: 240px;
        height: 440px;
        margin-top: 10%;
    }
    .carousel-button {
        top: 28%;
    }
}
@media screen and (max-width: 376px) {
    .card__body h3 {
        font-size: .9rem;
    }
    .card__body p {
        font-size: .7rem;
    }
    .card{
        width: 200px;
        height: 380px;
        margin-top: 6%;
    }
}